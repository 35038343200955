import React from 'react';
import PropTypes from 'prop-types';
import { withGoogleSheets } from 'react-db-google-sheets';

const H2Videos2022 = (props) => (
	<div className="Videos">
		<div className="flex content-center">
			<div className="flex-grow self-center px-4">
				<h2 className="mb-6">Video Highlights</h2>
			</div>
		</div>

		<div className="flex flex-wrap overflow-hidden -mx-2">
			{props.db.h22022videos.map((data) => (
				<div className="w-full md:w-1/2 px-6 mb-6 overflow-hidden" key={data.circuit}>
					<h3 className="mb-4">{data.circuit}</h3>
					<div className="videoWrapper">
						<iframe width="560" height="315" src={"https://www.youtube.com/embed/"+data.video} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
					</div>
				</div>
			))}
		</div>
	</div>
);

H2Videos2022.propTypes = {
	db: PropTypes.shape({
		h22022videos: PropTypes.arrayOf(PropTypes.	ect),
	}),
};

export default withGoogleSheets('h22022videos')(H2Videos2022);
