import React from 'react';
import PropTypes from 'prop-types';
import { withGoogleSheets } from 'react-db-google-sheets';

const H1Am2022 = (props) => (
	<div className="Overall">
	<div className="flex content-center">
		<div className="flex-grow self-center px-4">
			<h2 className="am">Am Championship</h2>
			<p><small>Dropped scores (DS) will appear after 6 rounds have been completed</small></p>
		</div>
	</div>

	<div className="flex">
		<div className="col px-4">
			<div className="overallTable table-responsive rounds">
				<table cellPadding="0" cellSpacing="0" border="0" className="table">
					<thead>
						<tr>
							<th>Pos</th>
							<th className="grow">Driver</th>
							<th className="overall">Overall</th>
							<th className="ds">DS</th>
							<th>R1 <span>Goodwood 105 1</span></th>
							<th>R2 <span>Goodwood 105 2</span></th>
							<th>R3 <span>Silverstone Giulia 1</span></th>
							<th>R4 <span>Silverstone Giulia 2</span></th>
							<th>R5 <span>Donington 156 1</span></th>
							<th>R6 <span>Donington 156 2</span></th>
							<th>R7 <span>Brands Hatch GP 147 1</span></th>
							<th>R8 <span>Brands Hatch GP 147 2</span></th>
							<th>R9 <span>Oulton 105 1</span></th>
							<th>R10 <span>Oulton 105 2</span></th>
							<th>R11 <span>Thruxton Giulia 1</span></th>
							<th>R12 <span>Thruxton Giulia 2</span></th>
							<th>R13 <span>Goodwood 156 1</span></th>
							<th>R14 <span>Goodwood 156 2</span></th>
							<th>R15 <span>Silverstone 147 1</span></th>
							<th>R16 <span>Silverstone 147 2</span></th>
							<th>R17 <span>Donington 105 1</span></th>
							<th>R18 <span>Donington 105 2</span></th>
							<th>R19 <span>Brands Hatch GP Giulia 1</span></th>
							<th>R20 <span>Brands Hatch GP Giulia 2</span></th>
							<th>R21 <span>Oulton 156 1</span></th>
							<th>R22 <span>Oulton 156 2</span></th>
							<th>R23 <span>Thruxton 147 1</span></th>
							<th>R24 <span>Thruxton 147 2</span></th>
							<th className="overall">Overall</th>
							<th className="ds">DS</th>
						</tr>
					</thead>
					<tbody>
					{props.db.h12022am.map((data) => (
								<tr className={data.class} key={data.driver}>
									<td>{data.pos}</td>
									<td className="grow"><span className="number" style={{color:data.colour}}>{data.number}</span> {data.driver}</td>
									<td><span>{data.overall}</span></td>
									<td><span>{data.dropped}</span></td>
									<td>{data.goodwood1051}</td>
									<td>{data.goodwood1052}</td>
									<td>{data.silverstoneGiulia1}</td>
									<td>{data.silverstoneGiulia2}</td>
									<td>{data.donington1561}</td>
									<td>{data.donington1562}</td>
									<td>{data.brandsHatchGP1471}</td>
									<td>{data.brandsHatchGP1472}</td>
									<td>{data.oulton1051}</td>
									<td>{data.oulton1052}</td>
									<td>{data.thruxtonGiulia1}</td>
									<td>{data.thruxtonGiulia2}</td>
									<td>{data.goodwood1561}</td>
									<td>{data.goodwood1562}</td>
									<td>{data.silverstone1471}</td>
									<td>{data.silverstone1472}</td>
									<td>{data.donington1051}</td>
									<td>{data.donington1052}</td>
									<td>{data.brandsHatchGPGiulia1}</td>
									<td>{data.brandsHatchGPGiulia2}</td>
									<td>{data.oulton1561}</td>
									<td>{data.oulton1562}</td>
									<td>{data.thruxton1471}</td>
									<td>{data.thruxton1472}</td>
									<td><span>{data.overall}</span></td>
									<td><span>{data.dropped}</span></td>
								</tr>
							))}
					</tbody>
				</table>
			</div>
		</div>
	</div>
</div>
);

H1Am2022.propTypes = {
	db: PropTypes.shape({
		h12022am: PropTypes.arrayOf(PropTypes.object),
	}),
};

export default withGoogleSheets('h12022am')(H1Am2022);
