import React from 'react';
import PropTypes from 'prop-types';
import { withGoogleSheets } from 'react-db-google-sheets';

const H2Am2022 = (props) => (
	<div className="Overall">
	<div className="flex content-center">
		<div className="flex-grow self-center px-4">
			<h2 className="am">Am Championship</h2>
			<p><small>Dropped scores (DS) will appear after 6 rounds have been completed</small></p>
		</div>
	</div>

	<div className="flex">
		<div className="col px-4">
			<div className="overallTable table-responsive rounds">
				<table cellPadding="0" cellSpacing="0" border="0" className="table">
					<thead>
						<tr>
							<th>Pos</th>
							<th className="grow">Driver</th>
							<th className="overall">Overall</th>
							<th className="ds">DS</th>
							<th>R1</th>
							<th>R2</th>
							<th>R3</th>
							<th>R4</th>
							<th>R5</th>
							<th>R6</th>
							<th>R7</th>
							<th>R8</th>
							<th>R9</th>
							<th>R10</th>
							<th>R11</th>
							<th>R12</th>
							<th>R13</th>
							<th>R14</th>
							<th>R15</th>
							<th>R16</th>
							<th>R17</th>
							<th>R18</th>
							<th>R19</th>
							<th>R20</th>
							<th>R21</th>
							<th>R22</th>
							<th>R23</th>
							<th>R24</th>
							<th className="overall">Overall</th>
							<th className="ds">DS</th>
						</tr>
					</thead>
					<tbody>
					{props.db.h22022am.map((data) => (
								<tr className={data.class} key={data.driver}>
									<td>{data.pos}</td>
									<td className="grow"><span className="number" style={{color:data.colour}}>{data.number}</span> {data.driver}</td>
									<td><span>{data.overall}</span></td>
									<td><span>{data.dropped}</span></td>
									<td>{data.r1}</td>
									<td>{data.r2}</td>
									<td>{data.r3}</td>
									<td>{data.r4}</td>
									<td>{data.r5}</td>
									<td>{data.r6}</td>
									<td>{data.r7}</td>
									<td>{data.r8}</td>
									<td>{data.r9}</td>
									<td>{data.r10}</td>
									<td>{data.r11}</td>
									<td>{data.r12}</td>
									<td>{data.r13}</td>
									<td>{data.r14}</td>
									<td>{data.r15}</td>
									<td>{data.r16}</td>
									<td>{data.r17}</td>
									<td>{data.r18}</td>
									<td>{data.r19}</td>
									<td>{data.r20}</td>
									<td>{data.r21}</td>
									<td>{data.r22}</td>
									<td>{data.r23}</td>
									<td>{data.r24}</td>
									<td><span>{data.overall}</span></td>
									<td><span>{data.dropped}</span></td>
								</tr>
							))}
					</tbody>
				</table>
			</div>
		</div>
	</div>
</div>
);

H2Am2022.propTypes = {
	db: PropTypes.shape({
		h22022am: PropTypes.arrayOf(PropTypes.object),
	}),
};

export default withGoogleSheets('h22022am')(H2Am2022);
