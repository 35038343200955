import React from 'react';
import PropTypes from 'prop-types';
import { withGoogleSheets } from 'react-db-google-sheets';

const H1Overall2021 = (props) => (
	<div className="Overall">
	<div className="flex content-center">
		<div className="flex-grow self-center px-4">
			<h2 className="pro">Overall Championship</h2>
			<p><small>Ordered by dropped score (DS)</small></p>
		</div>
	</div>

	<div className="flex">
		<div className="col px-4">
			<div className="overallTable table-responsive rounds">
				<table cellPadding="0" cellSpacing="0" border="0" className="table">
					<thead>
						<tr>
							<th>Pos</th>
							<th className="grow">Driver</th>
							<th className="overall">Overall</th>
							<th className="ds">DS</th>
						</tr>
					</thead>
					<tbody>
					{props.db.h12021overall.map((data) => (
						<tr className={data.class} key={data.driver}>
							<td>{data.pos}</td>
							<td className="grow"><span className="number" style={{color:data.colour}}>{data.number}</span> {data.driver}</td>
							<td><span>{data.overall}</span></td>
							<td><span>{data.dropped}</span></td>
						</tr>
					))}
					</tbody>
				</table>
			</div>
		</div>
	</div>
</div>
);

H1Overall2021.propTypes = {
	db: PropTypes.shape({
		h12021overall: PropTypes.arrayOf(PropTypes.object),
	}),
};

export default withGoogleSheets('h12021overall')(H1Overall2021);
