import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import GoogleSheetsProvider from "react-db-google-sheets";
import "./App.scss";
import H1Videos2024 from "./tabs/2024/H1Videos2024";
import H1Banner2024 from "./assets/img/H1Banner2024.jpg";
import H12024 from "./tabs/2024/H12024";
import H22023 from "./tabs/2023/H22023";
import H2Videos2023 from "./tabs/2023/H2Videos2023";
import H1Pro2023 from "./tabs/2023/H1Pro2023";
import H1Am2023 from "./tabs/2023/H1Am2023";
import H1Videos2023 from "./tabs/2023/H1Videos2023";
import H1Banner2023 from "./assets/img/H1Banner2023.jpg";
import H2Pro2022 from "./tabs/2022/H2Pro2022";
import H2Am2022 from "./tabs/2022/H2Am2022";
import H2Videos2022 from "./tabs/2022/H2Videos2022";
import H2Banner2022 from "./assets/img/H2Banner2022.jpg";
import H1Pro2022 from "./tabs/2022/H1Pro2022";
import H1Am2022 from "./tabs/2022/H1Am2022";
import H1Videos2022 from "./tabs/2022/H1Videos2022";
import H2Pro2021 from "./tabs/2021/H2Pro2021";
import H2Am2021 from "./tabs/2021/H2Am2021";
import H2Videos2021 from "./tabs/2021/H2Videos2021";
import H2Banner2021 from "./assets/img/H2Banner2021.jpg";
import H1Banner2022 from "./assets/img/H1Banner2022.jpg";
import H1Overall2021 from "./tabs/2021/H1Overall2021";
import H1Am2021 from "./tabs/2021/H1Am2021";
import H1Rounds2021 from "./tabs/2021/H1Rounds2021";
import H1Videos2021 from "./tabs/2021/H1Videos2021";
import H1Banner2021 from "./assets/img/H1Banner2021.jpg";

class App extends Component {
  render() {
    return (
      <GoogleSheetsProvider>
        <div className="App">
          <Tabs>
            <TabList>
              <Tab className="react-tabs__tab">2024 H1</Tab>
              <Tab className="react-tabs__tab">2023 H2</Tab>
              <Tab className="react-tabs__tab">2023 H1</Tab>
              <Tab className="react-tabs__tab">2022 H2</Tab>
              <Tab className="react-tabs__tab">2022 H1</Tab>
              <Tab className="react-tabs__tab">2021 H2</Tab>
              <Tab className="react-tabs__tab">2021 H1</Tab>
            </TabList>

            <TabPanel className="react-tabs__tab-panel main">
              <img src={H1Banner2024} alt="banner" />

              <div className="container px-2">
                <Tabs>
                  <TabList className="react-tabs__tab-list sub">
                    <Tab className="react-tabs__tab pro">
                      Overall Championship
                    </Tab>
                    <Tab>Highlights</Tab>
                  </TabList>

                  <TabPanel>
                    <H12024 />
                  </TabPanel>

                  <TabPanel>
                    <H1Videos2024 />
                  </TabPanel>
                </Tabs>
              </div>
            </TabPanel>

            <TabPanel className="react-tabs__tab-panel main">
              <img src={H1Banner2022} alt="banner" />

              <div className="container px-2">
                <Tabs>
                  <TabList className="react-tabs__tab-list sub">
                    <Tab className="react-tabs__tab pro">
                      Overall Championship
                    </Tab>
                    <Tab>Highlights</Tab>
                  </TabList>

                  <TabPanel>
                    <H22023 />
                  </TabPanel>

                  <TabPanel>
                    <H2Videos2023 />
                  </TabPanel>
                </Tabs>
              </div>
            </TabPanel>

            <TabPanel className="react-tabs__tab-panel main">
              <img src={H1Banner2023} alt="banner" />

              <div className="container px-2">
                <Tabs>
                  <TabList className="react-tabs__tab-list sub">
                    <Tab className="react-tabs__tab pro">Pro Championship</Tab>
                    <Tab className="react-tabs__tab am">AM Championship</Tab>
                    <Tab>Highlights</Tab>
                  </TabList>

                  <TabPanel>
                    <H1Pro2023 />
                  </TabPanel>

                  <TabPanel>
                    <H1Am2023 />
                  </TabPanel>

                  <TabPanel>
                    <H1Videos2023 />
                  </TabPanel>
                </Tabs>
              </div>
            </TabPanel>

            <TabPanel className="react-tabs__tab-panel main">
              <img src={H2Banner2022} alt="banner" />

              <div className="container px-2">
                <Tabs>
                  <TabList className="react-tabs__tab-list sub">
                    <Tab className="react-tabs__tab pro">Pro Championship</Tab>
                    <Tab className="react-tabs__tab am">AM Championship</Tab>
                    <Tab>Highlights</Tab>
                  </TabList>

                  <TabPanel>
                    <H2Pro2022 />
                  </TabPanel>

                  <TabPanel>
                    <H2Am2022 />
                  </TabPanel>

                  <TabPanel>
                    <H2Videos2022 />
                  </TabPanel>
                </Tabs>
              </div>
            </TabPanel>

            <TabPanel className="react-tabs__tab-panel main">
              <img src={H1Banner2022} alt="banner" />

              <div className="container px-2">
                <Tabs>
                  <TabList className="react-tabs__tab-list sub">
                    <Tab className="react-tabs__tab pro">Pro Championship</Tab>
                    <Tab className="react-tabs__tab am">AM Championship</Tab>
                    <Tab>Highlights</Tab>
                  </TabList>

                  <TabPanel>
                    <H1Pro2022 />
                  </TabPanel>

                  <TabPanel>
                    <H1Am2022 />
                  </TabPanel>

                  <TabPanel>
                    <H1Videos2022 />
                  </TabPanel>
                </Tabs>
              </div>
            </TabPanel>

            <TabPanel className="react-tabs__tab-panel main">
              <img src={H2Banner2021} alt="banner" />

              <div className="container px-2">
                <Tabs>
                  <TabList className="react-tabs__tab-list sub">
                    <Tab className="react-tabs__tab pro">Pro Championship</Tab>
                    <Tab className="react-tabs__tab am">AM Championship</Tab>
                    <Tab>Highlights</Tab>
                  </TabList>

                  <TabPanel>
                    <H2Pro2021 />
                  </TabPanel>

                  <TabPanel>
                    <H2Am2021 />
                  </TabPanel>

                  <TabPanel>
                    <H2Videos2021 />
                  </TabPanel>
                </Tabs>
              </div>
            </TabPanel>

            <TabPanel className="react-tabs__tab-panel main">
              <img src={H1Banner2021} alt="banner" />

              <div className="container px-2">
                <Tabs>
                  <TabList className="react-tabs__tab-list sub">
                    <Tab className="react-tabs__tab pro">Overall</Tab>
                    <Tab className="react-tabs__tab am">AM Championship</Tab>
                    <Tab>Round by Round</Tab>
                    <Tab>Highlights</Tab>
                  </TabList>

                  <TabPanel>
                    <H1Overall2021 />
                  </TabPanel>

                  <TabPanel>
                    <H1Am2021 />
                  </TabPanel>

                  <TabPanel>
                    <H1Rounds2021 />
                  </TabPanel>

                  <TabPanel>
                    <H1Videos2021 />
                  </TabPanel>
                </Tabs>
              </div>
            </TabPanel>
          </Tabs>
        </div>
      </GoogleSheetsProvider>
    );
  }
}

export default App;
