import React from 'react';
import PropTypes from 'prop-types';
import { withGoogleSheets } from 'react-db-google-sheets';

const H2Am2021 = (props) => (
	<div className="Overall">
	<div className="flex content-center">
		<div className="flex-grow self-center px-4">
			<h2 className="am">AM Championship</h2>
			<p><small>Ordered by dropped score (DS)</small></p>
		</div>
	</div>

	<div className="flex">
		<div className="col px-4">
			<div className="overallTable table-responsive rounds">
				<table cellPadding="0" cellSpacing="0" border="0" className="table">
					<thead>
						<tr>
							<th>Pos</th>
							<th className="grow">Driver</th>
							<th className="overall">Overall</th>
							<th className="ds">DS</th>
							<th>R1 <span>Donington 75 1</span></th>
							<th>R2 <span>Donington 75 2</span></th>
							<th>R3 <span>Silverstone TCR 1</span></th>
							<th>R4 <span>Silverstone TCR 2</span></th>
							<th>R5 <span>Silverstone TCR 3</span></th>
							<th>R6 <span>Brands Hatch 156 1</span></th>
							<th>R7 <span>Brands Hatch 156 2</span></th>
							<th>R8 <span>Snetterton 75 1</span></th>
							<th>R9 <span>Snetterton 75 2</span></th>
							<th>R10 <span>Donington TCR 1</span></th>
							<th>R11 <span>Donington  TCR 2</span></th>
							<th>R12 <span>Donington  TCR 3</span></th>
							<th>R13 <span>Silverstone 156 1</span></th>
							<th>R14 <span>Silverstone 156 2</span></th>
							<th>R15 <span>Brands Hatch 75 1</span></th>
							<th>R16 <span>Brands Hatch 75 2</span></th>
							<th>R17 <span>Brands Hatch 75 3</span></th>
							<th>R18 <span>Snetterton TCR 1</span></th>
							<th>R19 <span>Snetterton TCR 2</span></th>
							<th>R20 <span>Donington 156 1</span></th>
							<th>R21 <span>Donington 156 2</span></th>
							<th>R22 <span>Donington 156 3</span></th>
							<th>R23 <span>Silverstone 75 1</span></th>
							<th>R24 <span>Silverstone 75 2</span></th>
							<th>R25 <span>Silverstone 75 3</span></th>
							<th>R26 <span>Brands Hatch TCR 1</span></th>
							<th>R27 <span>Brands Hatch TCR 2</span></th>
							<th>R28 <span>Snetterton 156 1</span></th>
							<th>R29 <span>Snetterton 156 2</span></th>
							<th>R30 <span>Snetterton 156 3</span></th>
							<th className="overall">Overall</th>
							<th className="ds">DS</th>
						</tr>
					</thead>
					<tbody>
					{props.db.h22021am.map((data) => (
								<tr className={data.class} key={data.driver}>
									<td>{data.pos}</td>
									<td className="grow"><span className="number" style={{color:data.colour}}>{data.number}</span> {data.driver}</td>
									<td><span>{data.overall}</span></td>
									<td><span>{data.dropped}</span></td>
									<td>{data.donington751}</td>
									<td>{data.donington752}</td>
									<td>{data.silverstonetcr1}</td>
									<td>{data.silverstonetcr2}</td>
									<td>{data.silverstonetcr3}</td>
									<td>{data.brands1561}</td>
									<td>{data.brands1562}</td>
									<td>{data.snetterton751}</td>
									<td>{data.snetterton752}</td>
									<td>{data.doningtontcr1}</td>
									<td>{data.doningtontcr2}</td>
									<td>{data.doningtontcr3}</td>
									<td>{data.silverstone1561}</td>
									<td>{data.silverstone1562}</td>
									<td>{data.brands751}</td>
									<td>{data.brands752}</td>
									<td>{data.brands753}</td>
									<td>{data.snettertontcr1}</td>
									<td>{data.snettertontcr2}</td>
									<td>{data.donington1561}</td>
									<td>{data.donington1562}</td>
									<td>{data.donington1563}</td>
									<td>{data.silverstone751}</td>
									<td>{data.silverstone752}</td>
									<td>{data.silverstone753}</td>
									<td>{data.brandstcr1}</td>
									<td>{data.brandstcr2}</td>
									<td>{data.snetterton1561}</td>
									<td>{data.snetterton1562}</td>
									<td>{data.snetterton1563}</td>
									<td><span>{data.overall}</span></td>
									<td><span>{data.dropped}</span></td>
								</tr>
							))}
					</tbody>
				</table>
			</div>
		</div>
	</div>
</div>
);

H2Am2021.propTypes = {
	db: PropTypes.shape({
		h22021am: PropTypes.arrayOf(PropTypes.object),
	}),
};

export default withGoogleSheets('h22021am')(H2Am2021);
