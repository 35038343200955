import React from 'react';
import PropTypes from 'prop-types';
import { withGoogleSheets } from 'react-db-google-sheets';

const H1Rounds2021 = (props) => (
	<div className="Overall">
	<div className="flex content-center">
		<div className="flex-grow self-center px-4">
			<h2 className="pro">Overall Championship</h2>
			<p><small>Ordered by dropped score (DS)</small></p>
		</div>
	</div>

	<div className="flex">
		<div className="col px-4">
			<div className="overallTable table-responsive rounds">
				<table cellPadding="0" cellSpacing="0" border="0" className="table">
					<thead>
						<tr>
							<th>Pos</th>
							<th className="grow">Driver</th>
							<th>R1 <span>Castle Combe 1</span></th>
							<th>R2 <span>Castle Combe 2</span></th>
							<th>R3 <span>Anglesey 1</span></th>
							<th>R4 <span>Anglesey 2</span></th>
							<th>R5 <span>Anglesey 3</span></th>
							<th>R6 <span>Brands Hatch GP 1</span></th>
							<th>R7 <span>Brands Hatch GP 2</span></th>
							<th>R8 <span>Cadwell Park 1</span></th>
							<th>R9 <span>Cadwell Park 2</span></th>
							<th>R10 <span>Cadwell Park 3</span></th>
							<th>R11 <span>Goodwood 1</span></th>
							<th>R12 <span>Goodwood 2</span></th>
							<th>R13 <span>Goodwood 3</span></th>
							<th>R14 <span>Donington 1</span></th>
							<th>R15 <span>Donington 2</span></th>
							<th>R16 <span>Silverstone Nat 1</span></th>
							<th>R17 <span>Silverstone Nat 2</span></th>
							<th>R18 <span>Silverstone Nat 3</span></th>
							<th>R19 <span>Oulton Park 1</span></th>
							<th>R20 <span>Oulton Park 2</span></th>
							<th>R21 <span>Thruxton 1</span></th>
							<th>R22 <span>Thruxton 2</span></th>
							<th>R23 <span>Thruxton 3</span></th>
							<th>R24 <span>Knockhill 1</span></th>
							<th>R25 <span>Knockhill 2</span></th>
							<th>R26 <span>Croft 1</span></th>
							<th>R27 <span>Croft 2</span></th>
							<th>R28 <span>Croft 3</span></th>
							<th>R29 <span>Snetterton 300 1</span></th>
							<th>R30 <span>Snetterton 300 2</span></th>
							<th className="overall">Overall</th>
							<th className="ds">DS</th>
						</tr>
					</thead>
					<tbody>
						{props.db.h12021overall.map((data) => (
							<tr className={data.class} key={data.driver}>
								<td>{data.pos}</td>
								<td className="grow"><span className="number" style={{color:data.colour}}>{data.number}</span> {data.driver}</td>
								<td>{data.combe1}</td>
								<td>{data.combe2}</td>
								<td>{data.anglesey1}</td>
								<td>{data.anglesey2}</td>
								<td>{data.anglesey3}</td>
								<td>{data.brandGp1}</td>
								<td>{data.brandGp2}</td>
								<td>{data.cadwell1}</td>
								<td>{data.cadwell2}</td>
								<td>{data.cadwell3}</td>
								<td>{data.goodwood1}</td>
								<td>{data.goodwood2}</td>
								<td>{data.goodwood3}</td>
								<td>{data.donington1}</td>
								<td>{data.donington2}</td>
								<td>{data.silvNat1}</td>
								<td>{data.silvNat2}</td>
								<td>{data.silvNat3}</td>
								<td>{data.oulton1}</td>
								<td>{data.oulton2}</td>
								<td>{data.thruxton1}</td>
								<td>{data.thruxton2}</td>
								<td>{data.thruxton3}</td>
								<td>{data.knockhill1}</td>
								<td>{data.knockhill2}</td>
								<td>{data.croft1}</td>
								<td>{data.croft2}</td>
								<td>{data.croft3}</td>
								<td>{data.snetterton1}</td>
								<td>{data.snetterton2}</td>
								<td><span>{data.overall}</span></td>
								<td><span>{data.dropped}</span></td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	</div>
</div>
);

H1Rounds2021.propTypes = {
	db: PropTypes.shape({
		h12021overall: PropTypes.arrayOf(PropTypes.object),
	}),
};

export default withGoogleSheets('h12021overall')(H1Rounds2021);
